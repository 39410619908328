Vue.component('lista-aplicacoes', {
  data: function() {
    return {
        aplicacoes: [],
        paginaAtual: 1,
        totalRegistros: 0,
        pendingRequests: 0,
        showList: true
      };
  },
  template: '#lista-aplicacoes',
  computed: {
    totalPaginas: function() { return Pagination.totalPages(this.itensPorPagina, this.totalRegistros); },
    itensPorPagina: function() { return 15; },
    itensPaginacao: function() { return Pagination.paginationItems(this.paginaAtual, this.totalPaginas); }
  },
  events: {
    update_list: function() {
      this.showList = true;
      this.loadPage(this.paginaAtual);
    },
    show_list: function() {
      this.showList = true;
    }
  },
  methods: {
    create: function(id) {
      this.showList = false;
      this.$dispatch('create_application');
    },
    edit: function(id) {
      this.showList = false;
      this.$dispatch('edit_application', {id: id});
    },
    loadPage: function(page) {
      this.paginaAtual = page;
      var resource = this.$resource(cdv.settings.widgetUrl + 'api/application/{pageSize}/{page}');
      var data = {pageSize: this.itensPorPagina, page: this.paginaAtual};
      this.pendingRequests++;
      resource.get(data)
              .then(function(response) {
                var data = response.data;
                this.aplicacoes = data.data;
                this.totalRegistros = data.total;
              })
              .catch(function(response) {
                DtkNotification.handleResponseDisplayErrors('Falha ao obter aplicações!', response);
              })
              .finally(function() {
                this.pendingRequests--;
              });
    },
    loadLastPage: function() {
      var lastPage = this.totalPaginas;
      this.loadPage(lastPage);
    },
    remove: function(id) {
      if (!confirm('Deseja realmente remover a aplicação? Essa ação é irreversível.')) {
        return;
      }
      var resource = this.$resource(cdv.settings.widgetUrl + 'api/application/{id}');
      this.pendingRequests++;
      resource.remove({id: id})
              .then(function(response) {
                DtkNotification.notifySuccess('Aplicação removida com sucesso!');
                this.loadPage(this.paginaAtual);
              })
              .catch(function(response) {
                DtkNotification.handleResponseDisplayErrors('Falha ao remover aplicação!', response);
              })
              .finally(function() {
                this.pendingRequests--;
              });
    },
    loadFirstPage: function() {
      this.loadPage(1);
    }
  },
  created: function() {
    this.loadPage(1);
  }
});
