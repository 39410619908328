(function(exports, _) {
  exports.Pagination = {
    getInitialIndex: function(currentPage, totalPages) {
      if (currentPage == 1 || currentPage == 2) {
        return 1;
      }
      if (currentPage == totalPages - 2)  {
        return currentPage - 1;
      }
      if (currentPage == totalPages - 1)  {
        return currentPage - 2;
      }
      if (currentPage == totalPages && totalPages <= 3)  {
        return 1;
      }
      if (currentPage == totalPages)  {
        return currentPage - 3;
      }
      return currentPage - 1;
    },
    getFinalIndex: function(currentPage, totalPages) {
      if (currentPage == 1 && totalPages <= 3) {
        return currentPage + totalPages;
      }
      if (currentPage == 1) {
        return currentPage + 4;
      }
      if (currentPage == totalPages) {
        return currentPage + 1;
      }
      if (currentPage == totalPages - 1) {
        return totalPages + 1;
      }
      return currentPage + 3;
    },
    paginationItems: function(paginaAtual, totalPaginas) {
      if (totalPaginas == 0) { return []; }
      var initialIndex = Pagination.getInitialIndex(paginaAtual, totalPaginas);
      var finalIndex = Pagination.getFinalIndex(paginaAtual, totalPaginas);
      return _.range(initialIndex, finalIndex);
    },
    totalPages: function(itemsPerPage, totalItems) {
      return Math.floor(totalItems / itemsPerPage) + 1;
    }
  };
})(window, _);
