(function(exports, toastr, _) {
  'use strict';

  toastr.options = {'closeButton': false,
                    'debug': false,
                    'newestOnTop': false,
                    'progressBar': false,
                    'positionClass': 'toast-top-full-width',
                    'preventDuplicates': false,
                    'onclick': null,
                    'showDuration': '3000',
                    'hideDuration': '10000',
                    'timeOut': '50000',
                    'extendedTimeOut': '10000',
                    'showEasing': 'swing',
                    'hideEasing': 'linear',
                    'showMethod': 'fadeIn',
                    'hideMethod': 'fadeOut'};

  exports.DtkNotification = {
    notificationType: {'Error': 'Error',
                           'Warning': 'Warning',
                           'Success': 'Success',
                           'Info': 'Info'},
    notify: function(title, messages, type) {
        var messagesUlLi = '';
        if (messages && messages.length > 0) {
          messagesUlLi = '<ul class="notification-list">' + _.reduce(messages, function(acc, item) {
            return acc + '<li>' + item + '</li>';
          }, '') + '</ul>';
        }
        switch (type){
          case exports.DtkNotification.notificationType.Error:
            toastr.error(messagesUlLi, title);
          break;
          case exports.DtkNotification.notificationType.Success:
            toastr.success(messagesUlLi, title);
          break;
          case exports.DtkNotification.notificationType.Warning:
            toastr.warning(messagesUlLi, title);
          break;
          case exports.DtkNotification.notificationType.Info:
            toastr.info(messagesUlLi, title);
          break;
        }
      },
    notifySuccess: function(title) {
      exports.DtkNotification.notify(title,[], exports.DtkNotification.notificationType.Success);
    },
    notifyError: function(title, messages) {
      exports.DtkNotification.notify(title, messages, exports.DtkNotification.notificationType.Error);
    },
    notifyWarning: function(title, messages) {
      exports.DtkNotification.notify(title, messages, exports.DtkNotification.notificationType.Warning);
    },
    notifyInfo: function(title, messages) {
      exports.DtkNotification.notify(title, messages, exports.DtkNotification.notificationType.Info);
    },
    handleResponseDisplayErrors: function(title, response) {
      var errors = _.reduce(response.data, function(acc, i) { return _.union(acc, i); } , []);
      DtkNotification.notifyError(title, errors);
    }
  };
})(window, toastr, _);
