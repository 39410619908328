Vue.component('formulario-aplicacao', {
  template: '#formulario-aplicacao',
  data: function() {
      return {
        aplicacao: {
          'is_cadastro_publico': false
        },
        pendingRequests: 0,
        showForm: false
      };
    },
  events: {
    create_application: function() {
      this.aplicacao = {
        'is_cadastro_publico': false
      };
      this.showForm = true;
    },
    edit_application: function(data) {
      var resource = this.$resource(cdv.settings.widgetUrl + 'api/application/{id}');
      this.showForm = true;
      this.pendingRequests++;
      resource.get({id: data.id})
              .then(function(response) {
                this.aplicacao = response.data;
              })
              .catch(function(response) {
                DtkNotification.handleResponseDisplayErrors('Falha ao obter aplicação!', response);
              })
              .finally(function() {
                this.pendingRequests--;
              });
      this.aplicacao = {};
    }
  },
  methods: {
      save: function() {
        var resource = this.aplicacao.id ?
                       this.$resource(cdv.settings.widgetUrl + 'api/application/{id}') :
                       this.$resource(cdv.settings.widgetUrl + 'api/application');
        var logoInput = document.querySelector('#logoInput');
        var logoFile = logoInput.files[0];

        var data = new FormData();
        data.append('is_cadastro_publico', this.aplicacao.is_cadastro_publico);
        data.append('nome_sistema', this.aplicacao.nome_sistema);
        data.append('url_base', this.aplicacao.url_base);
        data.append('url_retorno_login', this.aplicacao.url_retorno_login);
        data.append('url_retorno_cadastro', this.aplicacao.url_retorno_cadastro);
        data.append('titulo', this.aplicacao.titulo);
        data.append('is_cadastro_publico', this.aplicacao.is_cadastro_publico);

        if (logoFile) {
          data.append('logo', logoFile);
        }

        var promise = this.aplicacao.id ?
                      resource.save({id: this.aplicacao.id}, data) :
                      resource.save(data);
        var self = this;
        this.pendingRequests++;
        promise.then(function() {
                 DtkNotification.notifySuccess('Aplicação salva com sucesso');
                 self.$dispatch('update_list');
                 self.showForm = false;
               })
               .catch(function(response) {
                 DtkNotification.handleResponseDisplayErrors('Falha ao salvar aplicação!', response);
               })
               .finally(function() {
                 this.pendingRequests--;
               });
      }
    }
});
