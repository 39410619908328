$().ready(function(window) {

  if (!$('[data-aplicacoes]').length) { return; }

  window.aplicacoes = new Vue({
    el: '[data-aplicacoes]',
    events: {
      create_application: function() {
        this.$broadcast('create_application');
      },
      edit_application: function(data) {
        this.$broadcast('edit_application', data);
      },
      update_list: function() {
        this.$broadcast('update_list');
      },
      show_list: function() {
        this.$broadcast('show_list');
      }
    }
  });

});
