$().ready(function(window) {

  if (!$('[data-alterar-senha]').length) { return; }

  window.alterarSenha = new Vue({
    el: '[data-alterar-senha]',
    data: {
      senha: '',
      resenha: '',
      pendingRequests: 0,
      id: $('[data-id-initialize]').val()
    },
    methods: {
      save: function() {
          var resource = this.$resource(cdv.settings.widgetUrl + 'account/password');
          var data = {senha: this.senha, resenha: this.resenha, id: this.id};
          this.pendingRequests++;
          resource.update(data)
                .then(function(response) {
                  this.senha = '';
                  this.resenha = '';
                  DtkNotification.notifySuccess('Senha alterada com sucesso');
                  setTimeout(function() {
                    location.href = Vue.http.options.root + '/account/show';
                  }, 1500);
                })
                .catch(function(response) {
                  DtkNotification.handleResponseDisplayErrors('Falha ao alterar senha!', response);
                  this.pendingRequests--;
                });
        }
    }
  });

});
