$().ready(function(window) {

  if (!$('[data-password-alter-execution]').length) { return; }

  window.alterarSenha = new Vue({
    el: '[data-password-alter-execution]',
    data: {
      senha: '',
      resenha: '',
      pendingRequests: 0,
      id: $('[data-init-id]').val(),
      executed: false
    },
    methods: {
      alterPassword: function() {
          var resource = this.$resource(cdv.settings.widgetUrl + 'api/execute-password-change');
          var data = {senha: this.senha, resenha: this.resenha, id: this.id};
          this.pendingRequests++;
          resource.update(data)
                .then(function(response) {
                  DtkNotification.notifySuccess('Senha alterada com sucesso!');
                  this.executed = true;
                })
                .catch(function(response) {
                  DtkNotification.handleResponseDisplayErrors('Falha ao alterar senha!', response);
                })
                .finally(function() {
                  this.pendingRequests--;
                });
        }
    }
  });

});
