$().ready(function(window) {

  if (!$('[data-contact]').length) { return; }

  window.contato = new Vue({
    el: '[data-contact]',
    data: {
      contato: {},
      executed: false,
      pendingRequests: 0
    },
    methods: {
      enviar: function() {
          var resource = this.$resource(cdv.settings.widgetUrl + 'api/contact');
          this.pendingRequests++;
          resource.save(this.contato)
                .then(function(response) {
                  DtkNotification.notifySuccess('Contato enviado com sucesso');
                  this.executed = true;
                })
                .catch(function(response) {
                  DtkNotification.handleResponseDisplayErrors('Falha ao enviar contato!', response);
                })
                .finally(function() {
                  this.pendingRequests--;
                });
        }
    }
  });

});
