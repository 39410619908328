$().ready(function(window) {

  if (!$('[data-password-change-requirement]').length) { return; }

  window.alterarSenha = new Vue({
    el: '[data-password-change-requirement]',
    data: {
      email: '',
      requirementSent: false,
      pendingRequests: 0
    },
    methods: {
      cancel: function() {
        location.href = './';
      },
      requirePasswordChange: function() {
          var resource = this.$resource(cdv.settings.widgetUrl + 'api/require-password-change');
          var data = {email: this.email};
          this.pendingRequests++;
          resource.save(data)
                .then(function(response) {
                  DtkNotification.notifySuccess('Solicitação de alteração de senha enviada com sucesso!');
                  this.requirementSent = true;
                })
                .catch(function(response) {
                  var msg = 'Falha ao enviar solicitação de alteração de senha!';
                  DtkNotification.handleResponseDisplayErrors(msg, response);
                })
                .finally(function() {
                  this.pendingRequests--;
                });
        }
    }
  });
});
